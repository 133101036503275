'use strict'

const CompsMigrationHelper = require('../helpers/CompsMigrationHelper')

function handleSlide(pageJson, cache, mobileView, comp) {
    comp.layout.y = 0
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': handleSlide
}

const migrationHelper = new CompsMigrationHelper(MIGRATION_MAP) // eslint-disable-line santa/no-module-state

module.exports = {exec: migrationHelper.migratePage.bind(migrationHelper)}